import React, { useState, useRef, useEffect } from "react";
import topnavclasses from "./navbar.module.css";
import User from "../../Assets/user.svg";
function TopNav() {
  const [notification, setNotification] = useState(false);
  const ref = useRef(null);

  let adminData = JSON.parse(localStorage.getItem("admindata"));
  useEffect(() => {
    // Function for click event
    function handleOutsideClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setNotification(false);
      }
    }

    // Adding click event listener
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [ref]);

  return (
    <div className={topnavclasses["TopNav"]}>
      <div className={topnavclasses["user"]}>
        <img src={User} alt="user" />
        <p>{adminData?.FirstName}</p>
      </div>
    </div>
  );
}

export default TopNav;
