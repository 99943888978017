import create from "zustand";

const initialState = {
    showNavbar: true,
    showSeo: false,
    // showMedbuzz: false,
    adminData: {},
    dropdown: '',
    shouldAnimate: false,
    prevItemName: null
};
const useStore = create((set) => ({
    ...initialState,
    setshowNavbar: (auth) =>
        set((state) => ({ ...state, showNavbar: !state.showNavbar })),
    setshowNSeo: (auth) =>
        set((state) => ({ ...state, showSeo: !state.showSeo })),
    // setshowMedbuzz: (auth) =>
    //     set((state) => ({ ...state, showMedbuzz: !state.showMedbuzz })),
    setAdminData: (data) => set((state) => ({ ...state, adminData: data })),
    setDropdown: (data) => set((state) => ({ ...state, dropdown: data })),
    setToken: (data) => set((state) => ({ ...state, Token: data })),
    setShouldAnimate: (data) => set((state) => ({ ...state, shouldAnimate: data })),
    setPrevItemName: (data) => set((state) => ({ ...state, setPrevItemName: data })),
}));
export default useStore;
