import React, { useEffect, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import Layout from "../../Components/Layout/layout";
import { headerstyles, override } from "./helper";
import AddConfig from "./AddConfig";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { Services } from "../../Services";
import { useNavigate, useSearchParams } from "react-router-dom";

const Config = () => {
  const [id, setId] = useState();
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoad] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [category, setCategory] = useState(false);
  const [formData, setFormData] = useState({});
  const [params] = useSearchParams();
  const [editable, setEditable] = useState(false);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();

  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  useEffect(() => {
    getCategories();
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
    }
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  const getCategories = () => {
    setLoading(true);
    Services.getConfig("GET", null, token)
      .then((data) => {
        if (data.Status === 1) {
          setConfigData(data.configData);
          setFormData(data.configData);
        } else if (data.Status === 0) {
          ToastError(data.Message);
          if (data.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((error) => {
        console.error("Error Fetching data :", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "ConfigID",
      text: "CONFIG ID",
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },

    {
      dataField: "Key",
      text: "KEY",
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Value",
      text: "VALUE",
      formatter: (cell) => renderValue(cell),
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: (cell, row) => (
        <button onClick={() => edithandler(row.ConfigID)}>
          <FaRegEdit size={18} />
        </button>
      ),
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
  ];

  const renderValue = (config) => {
    if (config && typeof config === "object") {
      if (Array.isArray(config)) {
        return <span>{config.join(",")}</span>;
      } else {
        return Object.keys(config).map((key) => (
          <div key={key}>
            <span>{key}:</span> {config[key]}
          </div>
        ));
      }
    } else {
      return config;
    }
  };

  const edithandler = (configID) => {
    setId(configID);
    setEditable(true);
    setCategory(true);
  };

  const emptyDataMessage = () => {
    return <p>No data to display</p>;
  };

  const paginationOptions = {
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Config?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  };

  return (
    <>
      {category && (
        <AddConfig
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={getCategories}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}
      <Layout
        className={dashboardclasses["dashboard-wrapper"]}
        Active={"Config"}
      >
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>Config Documents</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              {/* <div className={dashboardclasses["category-form-two"]}>
                <button onClick={categoryHandler}>+ Add Config</button>
              </div> */}
              <div className="responsive-table-container">
                <BootstrapTable
                  bootstrap4
                  keyField="ConfigID"
                  data={configData}
                  columns={columns}
                  noDataIndication={emptyDataMessage}
                  pagination={paginationFactory(paginationOptions)}
                />
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Config;
