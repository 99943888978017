import { css } from "@emotion/react";
export const headerstyles = {
  color: "#707275",
  fontWeight: 700,
  fontSize: "0.9rem",
};
export const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
