import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../App.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { headerstyles, override } from "./helper";
import AddCoupon from "./AddCoupon";
function Coupons() {
  const Navigate = useNavigate();
  const [id, setId] = useState();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1);
  const [category, setCategory] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  //   const [status, setStatus] = useState(2);
  const [editable, setEditable] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categorysearchItems, setCategorySearchItems] = useState([]);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  useEffect(() => {
    getcategories();
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
      // console.log(typeof params.get("page"));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
  }, [categorysearchItems]);

  const getcategories = () => {
    Services.getCoupons("GET", null, token)
      .then((res) => {
        setLoading(false);
        console.log("1", res.Coupons);
        if (res.Status === 1) {
          setCategoryItems(res.Coupons);
          setCategorySearchItems(res.Coupons);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };

  // const rowClickHandler = {
  //   onClick: (e, row) => {
  //     if (row && row.CouponCodeID) {
  //       const couponID = row.CouponCodeID;
  //       Navigate(`/Coupons/${couponID}`);
  //     } else {
  //       console.error("Invalid row or missing Coupon CodeID");
  //     }
  //   },
  // };

  const edithandler = (e) => {
    console.log(e.currentTarget.getAttribute("id"));
    setId(e.currentTarget.getAttribute("id"));
    setEditable(true);
    setCategory(true);
  };
  const deleteHandler = (e) => {
    if (window.confirm("are you sure you want to delete ")) {
      Services.deleteCoupon(
        "PATCH",
        null,
        token,
        e.currentTarget.getAttribute("id")
      )
        .then((res) => {
          if (res.Status === 1) {
            getcategories();
            ToastSuccess(res.Message, "success");
          } else if (res.Status === 0) {
            ToastError(res.Message, "error");
          }
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };

  const ActionFormatter = (cell, cellcontent) => {
    console.log(cellcontent);
    return (
      <div className={dashboardclasses.tablebtns}>
        <button onClick={edithandler} id={cellcontent.CouponCodeID}>
          <FaRegEdit size={18} />
        </button>
        <button onClick={deleteHandler} id={cellcontent.CouponCodeID}>
          <MdDelete size={18} />
        </button>
      </div>
    );
  };
  const statusFormatter = (cell, cellcontent) => {
    let classname =
      cell === 1
        ? dashboardclasses["status_active"]
        : dashboardclasses["status_inactive"];
    return (
      <span className={classname} style={{ textAlign: "center" }}>
        {cell === 1 ? "Active" : "Inactive"}
      </span>
    );
  };
  const typeFormatter = (cell, cellcontent) => {
    let classname =
      cell === 1
        ? dashboardclasses["status_active"]
        : cell === 2
        ? dashboardclasses["status_active"]
        : dashboardclasses["null"];
    return (
      <span className={classname} style={{ textAlign: "center" }}>
        {cell === 1 ? "10%" : cell === 2 ? "10Rs" : ""}
      </span>
    );
  };

  const columns = [
    {
      dataField: "CouponCode",
      text: "COUPON CODE",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Description",
      text: "DESCRIPTION",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "200px" };
      },
    },
    {
      dataField: "OfferValue",
      text: "OFFERVALUE",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "MaxDiscount",
      text: "MAX_DISCOUNT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },

    {
      dataField: "StartDate",
      text: "START DATE",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "EndDate",
      text: "END DATE",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Type",
      text: "TYPE",
      sort: false,
      formatter: typeFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "Status",
      text: "STATUS",
      sort: false,
      formatter: statusFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },

    {
      dataField: "ACTIONS",
      text: "ACTIONS",
      sort: false,
      formatter: ActionFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
  ];
  const pagination = paginationFactory({
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Coupons?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const defaultSorted = [
    {
      dataField: "CouponCode",
      order: "desc",
    },
  ];
  const searchHandler = (e) => {
    setTableLoad(true);
    Navigate(`/Coupons?page=${1}`);
    setpageCount(1);
    const search = categoryItems.filter((item) =>
      item.CouponCode.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log(search);
    setCategorySearchItems(search);
  };
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      {category && (
        <AddCoupon
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={getcategories}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}

      <Layout
        className={dashboardclasses["dashboard-wrapper"]}
        Active={"Coupons"}
      >
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>Coupons Information</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              <div className={dashboardclasses["category-form-two"]}>
                <input
                  type={"text"}
                  placeholder="search by CouponCode"
                  onChange={searchHandler}
                />

                <button onClick={categoryHandler}>+ Add Coupon</button>
              </div>
              {tableLoad ? (
                <div className={dashboardclasses["loader"]}>
                  <ScaleLoader
                    color={"black"}
                    loading={true}
                    css={override}
                    size={150}
                  />
                </div>
              ) : (
                <div className="category-table">
                  <BootstrapTable
                    bootstrap4
                    keyField="BookingID"
                    data={categorysearchItems}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    // rowEvents={rowClickHandler}
                    noDataIndication={emptyDataMessage}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default Coupons;
