import React, { useEffect } from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/login/login-office.jpeg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";
// import { menuItems } from "../../Components/Navbar/config";
import useStore from "../../store";
function Login() {
  const Navigate = useNavigate();
  const setadmindata = useStore((State) => State.setAdminData);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const adminData = localStorage.getItem("admindata");

    if (token && adminData) {
      Navigate("/Users");
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const body = JSON.stringify(data);
    Services.login("POST", body)
      .then((response) => {
        if (response.Status === 1) {
          localStorage.setItem("admindata", JSON.stringify(response?.Admin));
          setadmindata(response?.adminDetail);
          localStorage.setItem("token", response?.Token);

          //? Navigation part removed and redirected to warehouse

          // const navitem = menuItems?.filter((item) => {
          //     console.log(
          //         response?.adminDetails.adminroldeinfo.accessItems[0]
          //     );
          //     return (
          //         item.name ==
          //         response?.adminDetails.adminroldeinfo.accessItems[0]
          //     );
          // });
          // console.log(navitem);
          // Navigate(navitem[0].menuLink);

          //?remove below if above code is uncommented
          Navigate("/Users");
        } else if (response.Status === 0) {
          toast.error(`${response.Message}`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };
  return (
    <div className={LoginClasses["login-wrapper"]}>
      <div className={LoginClasses["login-subwrapper"]}>
        <img src={LoginLogo} alt="login-logo" />

        <div className={LoginClasses["login-form"]}>
          <h3>Login</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={LoginClasses["form-control"]}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                {...register("EmailID", {
                  required: "Email is required!",
                })}
              />
              {errors?.EmailID && (
                <p className={LoginClasses.error}>{errors?.EmailID?.message}</p>
              )}
            </div>
            <div className={LoginClasses["form-control"]}>
              <label htmlFor="password">Password</label>

              <input
                type="password"
                {...register("Password", {
                  required: "Password is required!",
                })}
              />
              {errors?.Password && (
                <p className={LoginClasses.error}>
                  {errors?.Password?.message}
                </p>
              )}
            </div>
            <input type="submit" value="Log in" />
            <span className={LoginClasses.border}></span>
          </form>

          {/* <div className={LoginClasses["login-links"]}>
                        <NavLink to="/forgotpassword">
                            forgot your Password?
                        </NavLink>
                        <NavLink to="/createaccount">
                            Create Your Account
                        </NavLink>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
