import uuid from "react-uuid";

import { FaUsers } from "react-icons/fa";
import { GrStatusInfo } from "react-icons/gr";
import { PiUserListFill } from "react-icons/pi";
import { MdEmojiTransportation } from "react-icons/md";
import { RiCoupon3Line } from "react-icons/ri";
import { FaQuoteRight } from "react-icons/fa6";
import { GrDocumentConfig } from "react-icons/gr";
export const menuItems = [
  {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/Users?page=1",
    active: "Users",
    icon: FaUsers,
  },

  {
    id: uuid(),
    multiple: false,
    name: "Bookings",
    menuLink: "/Bookings?page=1",
    active: "Bookings",
    icon: GrStatusInfo,
  },

  {
    id: uuid(),
    multiple: false,
    name: "Drivers",
    menuLink: "/Drivers?page=1",
    active: "Drivers",
    icon: PiUserListFill,
  },
  {
    id: uuid(),
    multiple: false,
    name: "TransportModes",
    menuLink: "/Transports?page=1",
    active: "TransportModes",
    icon: MdEmojiTransportation,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Coupons",
    menuLink: "/Coupons?page=1",
    active: "Coupons",
    icon: RiCoupon3Line,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Config",
    menuLink: "/Config?page=1",
    active: "Config",
    icon: GrDocumentConfig,
  },
  {
    id: uuid(),
    multiple: false,
    name: "FAQS",
    menuLink: "/Faqs?page=1",
    active: "FAQS",
    icon: FaQuoteRight,
  },
];
