import React, { useEffect, useRef, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddConfig(props) {
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState({ Key: "", Value: "" });

  useEffect(() => {
    if (props.editable) {
      Services.getConfigByID("GET", null, token, props.id)
        .then((res) => {
          console.log(res.configData);
          if (res?.Status === 1) {
            setFormData(res.configData);
            if (Array.isArray(res.configData.Value)) {
              reset({ Value: res.configData.Value.join(",") });
              console.log(res.configData.Value.join(","), "1");
            } else if (typeof res.configData.Value === "object") {
              reset(res.configData.Value);
            } else {
              reset({ Value: res.configData.Value });
            }
          } else if (res?.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  }, [props.editable, props.id, Navigate]);

  const CancelHandler = (e) => {
    e.stopPropagation();
    props.setmodal(false);
    props.setEditable(false);
  };
  const openhandler = (e) => {
    e.stopPropagation();
  };

  const onSubmit = (data) => {
    console.log(data);

    // let body = {
    //   ...data,
    // };
    // if (
    //   typeof data.Value === "string" &&
    //   data.Value.includes("From Time") &&
    //   data.Value.includes("To Time")
    // ) {
    //   console.log("Before transformation:", data.Value);

    //   const keyValuePairs = data.Value.split(",");
    //   const valueObject = {};

    //   keyValuePairs.forEach((pair) => {
    //     const [key, ...value] = pair.split(":").map((item) => item.trim());

    //     valueObject[key.trim()] = value.join(":").trim();
    //   });

    //   console.log("After transformation:", valueObject);

    //   body = {
    //     ...data,
    //     Value: valueObject,
    //   };
    // } else if (typeof data.Value === "string") {
    //   const valueArray = data.Value.split(",");

    //   if (valueArray.length === 1 && !isNaN(valueArray[0].trim())) {
    //     body = {
    //       ...data,
    //       Value: Number(valueArray[0].trim()),
    //     };
    //   } else {
    //     const numericValues = valueArray.map((item) =>
    //       !isNaN(item.trim()) ? Number(item.trim()) : item.trim()
    //     );

    //     body = {
    //       ...data,
    //       Value: numericValues,
    //     };
    //   }
    // } else {
    //   if (typeof data.Value === "number") {
    //     body = {
    //       ...data,
    //       Value: Number(data.Value),
    //     };
    //     console.log(data.Value, "HIII");
    //   }
    // }

    if (!props.editable) {
      props.tableload(true);
      Services.AddConfig(
        "POST",
        JSON.stringify({ Value: Number(data.Value) }),
        token
      )
        .then((res) => {
          if (res.Status === 1) {
            Navigate(`/Config?page=${1}`);
            props.setpageCount(1);
            props.setmodal(false);
            props.getCategories();
            props.tableload(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            props.tableload(false);
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          props.tableload(false);
          alert("something went wrong please try again");
          console.log(err);
        });
    } else if (props.editable) {
      let body = {};
      if (Array.isArray(formData.Value)) {
        console.log(data);
        body = { Value: data.Value.split(",") };
      } else if (typeof formData.Value === "object") {
        body = { Value: data };
      } else {
        body = { Value: data.Value };
      }
      console.log(body);

      Services.UpdateConfig("PATCH", JSON.stringify(body), token, props.id)
        .then((res) => {
          if (res.Status === 1) {
            props.setmodal(false);
            props.setEditable(false);
            props.getCategories();
            props.setEditable(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log(res);
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })

        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.key == "Escape") {
        console.log("close");
        props.setmodal(false);
        props.setEditable(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div className={dashboardclasses.modal} onClick={CancelHandler}>
      <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
        <div className={dashboardclasses["modal-content-header"]}>
          <div>
            <h3>{props.editable ? "Update Config" : "Add Config"}</h3>
          </div>
          <button
            className={dashboardclasses["close-btn"]}
            onClick={CancelHandler}
          >
            <IoClose size={22} color={"red"} />
          </button>
        </div>
        <form
          className={dashboardclasses["add-items"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          {!props.editable && (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="Key">Key</label>
              <div className={dashboardclasses["select"]}>
                <input
                  {...register("Key", {
                    required: "Key is required",
                  })}
                />
                {errors?.Key && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.Key?.message}
                  </p>
                )}
              </div>
            </div>
          )}
          {formData.Value &&
          typeof formData.Value === "object" &&
          !Array.isArray(formData.Value) ? (
            Object.keys(formData.Value).map((item, index) => {
              // console.log("test", item);
              return (
                <div
                  className={dashboardclasses["item-image-upload"]}
                  key={item}
                >
                  <label htmlFor={item}>{item}</label>
                  <div className={dashboardclasses["select"]}>
                    <input
                      {...register(item, {
                        required: `${item} is required`,
                      })}
                    />
                    {errors?.[item] && (
                      <p className={dashboardclasses["error"]}>
                        {errors?.[item]?.message}
                      </p>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="Value">Value</label>
              <div className={dashboardclasses["select"]}>
                <input
                  {...register("Value", {
                    required: `Value is required`,
                  })}
                />
                {errors?.Value && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.Value?.message}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className={dashboardclasses["add-btn"]}>
            <button type="button" onClick={CancelHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className={dashboardclasses["add-category-btn"]}
            >
              {props.editable ? "update" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddConfig;
