import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../App.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { headerstyles, override } from "./helper";
import AddFAQ from "./AddFAQ";
function FAQs() {
  const Navigate = useNavigate();
  const [id, setId] = useState();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1);
  const [category, setCategory] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  //   const [status, setStatus] = useState(2);
  const [editable, setEditable] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categorysearchItems, setCategorySearchItems] = useState([]);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  useEffect(() => {
    getcategories();
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
      // console.log(typeof params.get("page"));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
  }, [categorysearchItems]);

  const getcategories = () => {
    Services.getFaQs("GET", null, token)
      .then((res) => {
        setLoading(false);
        // console.log("1", res.FAQ);
        if (res.Status === 1) {
          setCategoryItems(res.FAQ);
          setCategorySearchItems(res.FAQ);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };

  const edithandler = (e) => {
    console.log(e.currentTarget.getAttribute("id"));
    setId(e.currentTarget.getAttribute("id"));
    setEditable(true);
    setCategory(true);
  };

  const deleteHandler = (e) => {
    if (window.confirm("Are you sure you want to delete ")) {
      Services.deleteFaQ(
        "DELETE",
        null,
        token,
        e.currentTarget.getAttribute("id")
      )
        .then((res) => {
          if (res.Status === 1) {
            getcategories();
            ToastSuccess(res.Message, "success");
          } else if (res.Status === 0) {
            ToastError(res.Message, "error");
          }
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };

  const ActionFormatter = (cell, cellcontent) => {
    console.log(cellcontent);
    return (
      <div className={dashboardclasses.tablebtns}>
        <button onClick={edithandler} id={cellcontent.CustomerSupport}>
          <FaRegEdit size={18} />
        </button>

        <button onClick={deleteHandler} id={cellcontent.CustomerSupport}>
          <MdDelete size={18} />
        </button>
      </div>
    );
  };
  const statusFormatter = (cell, cellcontent) => {
    let classname =
      cell === 1
        ? dashboardclasses["status_active"]
        : dashboardclasses["status_inactive"];
    return (
      <span className={classname} style={{ textAlign: "center" }}>
        {cell === 1 ? "Active" : "Inactive"}
      </span>
    );
  };

  const columns = [
    // {
    //   dataField: "CustomerSupport",
    //   text: "CUSTOMER SUPPORT",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { ...headerstyles, width: "150px" };
    //   },
    // },
    {
      dataField: "Question",
      text: "QUESTION",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "200px" };
      },
    },
    {
      dataField: "Answer",
      text: "ANSWER",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "CreatedAt",
      text: "CREATED AT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "250px" };
      },
    },

    {
      dataField: "UpdatedAt",
      text: "UPDATED AT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Type",
      text: "TYPE",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "Status",
      text: "STATUS",
      sort: false,
      formatter: statusFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },

    {
      dataField: "ACTIONS",
      text: "ACTIONS",
      sort: false,
      formatter: ActionFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
  ];
  const pagination = paginationFactory({
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Faqs?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const defaultSorted = [
    {
      dataField: "Question",
      order: "desc",
    },
  ];
  // const searchHandler = (e) => {
  //   setTableLoad(true);
  //   Navigate(`/Faqs?page=${1}`);
  //   setpageCount(1);
  //   const search = categoryItems.filter((item) =>
  //     item..toLowerCase().includes(e.target.value.toLowerCase())
  //   );
  //   console.log(search);
  //   setCategorySearchItems(search);
  // };
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      {category && (
        <AddFAQ
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={getcategories}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}

      <Layout className={dashboardclasses["dashboard-wrapper"]} Active={"FAQS"}>
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>FAQS Information</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              <div className={dashboardclasses["category-form-two"]}>
                {/* <input
                  type={"text"}
                  placeholder="search by"
                  onChange={searchHandler}
                /> */}

                <button onClick={categoryHandler}>+ Add FAQ</button>
              </div>
              {tableLoad ? (
                <div className={dashboardclasses["loader"]}>
                  <ScaleLoader
                    color={"black"}
                    loading={true}
                    css={override}
                    size={150}
                  />
                </div>
              ) : (
                <div className="category-table">
                  <BootstrapTable
                    bootstrap4
                    keyField="CustomerSupport"
                    data={categorysearchItems}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    noDataIndication={emptyDataMessage}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default FAQs;
