import React, { useEffect, useRef, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
function AddFAQ(props) {
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  //   let states = State.getStatesOfCountry("IN");
  const [value, setValue] = useState();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.editable) {
      Services.getFaQ("GET", null, token, props.id)
        .then((res) => {
          console.log(res.Coupon);
          if (res?.Status === 1) {
            const formData = {
              Question: res.Question,
              Answer: res.Answer,
              Status: res.Status != null ? res.Status.toString() : "",
              Type: res.Type != null ? res.Type.toString() : "",
            };

            reset(res.FAQ);
          } else if (res?.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [props.editable, props.id, setValue, Navigate]);

  const CancelHandler = (e) => {
    e.stopPropagation();
    props.setmodal(false);
    props.setEditable(false);
  };
  const openhandler = (e) => {
    e.stopPropagation();
  };

  const onSubmit = (data) => {
    console.log(data);

    let method = JSON.stringify({
      ...data,
    });
    if (!props.editable) {
      props.tableload(true);
      Services.AddFaQ("POST", method, token)
        .then((res) => {
          if (res.Status === 1) {
            Navigate(`/Faqs?page=${1}`);
            props.setpageCount(1);
            props.setmodal(false);
            props.getCategories();
            props.tableload(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            props.tableload(false);
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          props.tableload(false);
          alert("something went wrong please try again");
          console.log(err);
        });
    } else if (props.editable) {
      Services.updateFaQ("PATCH", method, token, props.id)
        .then((res) => {
          if (res.Status === 1) {
            props.setmodal(false);
            props.setEditable(false);
            props.getCategories();
            props.setEditable(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const close = (e) => {
      if (e.key == "Escape") {
        console.log("close");
        props.setmodal(false);
        props.setEditable(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div className={dashboardclasses.modal} onClick={CancelHandler}>
      <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
        <div className={dashboardclasses["modal-content-header"]}>
          <div>
            <h3>{props.editable ? "Update FAQ" : "Add FAQ"}</h3>
          </div>
          <button
            className={dashboardclasses["close-btn"]}
            onClick={CancelHandler}
          >
            <IoClose size={22} color={"red"} />
          </button>
        </div>
        <form
          className={dashboardclasses["add-items"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Question">Question</label>
            <div className={dashboardclasses["select"]}>
              <input
                {...register("Question", {
                  required: "Question is required",
                })}
              />
              {errors?.Question && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Question?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Answer">Answer</label>
            <div className={dashboardclasses["select"]}>
              <input
                {...register("Answer", {
                  required: "Answer is required",
                })}
              />
              {errors?.Answer && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Answer?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Status">Status</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("Status", {
                  required: "Status is required",
                })}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
              {errors?.Status && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Status?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Type">Type</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("Type", {
                  required: "Type is required",
                })}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value={1}>Driver Faqs</option>
                <option value={2}>User Faqs</option>
              </select>
              {errors?.Type && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Type?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["add-btn"]}>
            <button type="button" onClick={CancelHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className={dashboardclasses["add-category-btn"]}
            >
              {props.editable ? "updateFaQ" : "Add FaQ"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddFAQ;
