import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessage";
import Layout from "../../Components/Layout/layout";
import dashboardclasses from "../dashboard.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { override } from "./helper";

const Booking = () => {
  const { bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();

  useEffect(() => {
    if (bookingId) {
      getCategories(bookingId);
    } else {
      console.error("Booking ID not found in URL params");
    }
  }, [bookingId]);

  const getCategories = (bookingId) => {
    Services.bookingByID("GET", null, token, bookingId)
      .then((res) => {
        setLoading(false);
        console.log(res.Booking);
        if (res.Status === 1) {
          setBooking(res.Booking[0]);
        } else if (res.Status === 0) {
          ToastError(res.Booking);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      <Layout>
        <button
          style={{
            border: "none",
            padding: "5px",
            margin: "5px 20px",
            fontSize: "20px",
            borderBottom: "2px solid #0e9f6e",
          }}
          className="back_btn"
          onClick={() => {
            Navigate(-1);
          }}
        >
          Back
        </button>
        <div className="container mt-5">
          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            booking && (
              <div className="card driver-card">
                <div className="card-body">
                  <h1 className="card-title " style={{ color: "#4A646C" }}>
                    Booking Details
                  </h1>
                  <div className="row">
                    <div className="col-md-8">
                      <h1 className="card-title2">Booking Info</h1>
                      <p>
                        <strong>Name:</strong>
                        {"  "}
                        {booking.PickupAddress &&
                          booking.PickupAddress.UserName}
                      </p>
                      {/* <p>
                        <strong>Mobile Number:</strong>
                        {booking.UserMobileNumber}
                      </p>
                      <p>
                        <strong>Email:</strong> {booking.UserEmailID}
                      </p> */}
                      <p>
                        <strong>Booking Time and Date:</strong>
                        {"  "}
                        {`${booking.BookTime} ${booking.BookDate}`}
                      </p>

                      <p>
                        <strong>BookingStatus:</strong>
                        {"  "}
                        {booking.BookingStatus === 1
                          ? "ride end"
                          : booking.BookingStatus === 2
                          ? "pending"
                          : booking.BookingStatus === 3
                          ? "accepted"
                          : booking.BookingStatus === 4
                          ? "pickup"
                          : booking.BookingStatus === 5
                          ? "canceled after pickup(pending)"
                          : booking.BookingStatus === 6
                          ? "user cancel booking"
                          : "unknown"}
                      </p>
                      <p>
                        <strong>Pickup Address:</strong>
                        {"  "}
                        {booking.PickupAddress && booking.PickupAddress.Address}
                      </p>
                      <p>
                        <strong>Drop Address:</strong>
                        {"  "}
                        {booking.DropAddress && booking.DropAddress.Address}
                      </p>
                      <p>
                        <strong>Distance:</strong>
                        {"  "} {booking.Distance}
                      </p>
                      <p>
                        <strong>Vehicle Type:</strong>
                        {"  "}
                        {booking.BookingVehicleType === 1
                          ? "Auto"
                          : booking.BookingVehicleType === 2
                          ? "Car"
                          : booking.BookingVehicleType === 3
                          ? "Bike"
                          : booking.BookingVehicleType === 4
                          ? "driver"
                          : booking.BookingVehicleType === 5
                          ? "lady driver"
                          : "unkown"}
                      </p>
                      <p>
                        <strong>Payment Method:</strong>
                        {"  "}
                        {booking.PaymentMethod === 1
                          ? "COD"
                          : booking.PaymentMethod === 2
                          ? "Online"
                          : booking.PaymentMethod === 3
                          ? "Wallet"
                          : "Unknown"}
                      </p>
                      <p>
                        <strong>Transport Name:</strong>
                        {"  "} {booking.TransportName}
                      </p>
                      <p>
                        <strong>Total Paid Bill:</strong>
                        {"  "} {booking.GrandTotal}
                      </p>
                      <p>
                        <strong>BookingFare:</strong>
                        {"  "} {booking.BookingFare}
                      </p>

                      <h2 className="card-title3">Reviews & Ratings:-</h2>
                      <p>
                        <strong>Ride Fare:</strong>
                        {"  "} {booking.RideFare}
                      </p>
                      <p>
                        <strong>Ratings:</strong>
                        {"  "} {booking.Ratings}
                      </p>
                      <p>
                        <strong>Reviews:</strong>
                        {"  "} {booking.Reviews}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </Layout>
    </>
  );
};

export default Booking;
