import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessage";
import Layout from "../../Components/Layout/layout";
import dashboardclasses from "../dashboard.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { override } from "./helper";
const Coupon = () => {
  const { couponID } = useParams();
  const [couponData, setCouponData] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();

  useEffect(() => {
    if (couponID) {
      getCategories(couponID);
    } else {
      console.error("User ID not found in URL params");
    }
  }, [couponID]);

  const getCategories = (couponID) => {
    Services.getCoupon("GET", null, token, couponID)
      .then((res) => {
        setLoading(false);
        console.log(res.Coupon);
        if (res.Status === 1) {
          setCouponData(res.Coupon);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      <Layout>
        <button
          style={{
            border: "none",
            padding: "5px",
            margin: "5px 20px",
            fontSize: "20px",
            borderBottom: "2px solid #0e9f6e",
          }}
          className="back_btn"
          onClick={() => {
            Navigate(-1);
          }}
        >
          Back
        </button>
        <div className="container mt-5">
          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
           couponData && (
              <div className="card driver-card">
                <div className="card-body">
                  <h1 className="card-title" style={{color:"palevioletred"}}>Coupon Deatils</h1>
                  <div className="row">
                    {/* <div className="col-md-4">
                      <img
                        src={couponData.ProfilePicture}
                        alt="Driver Profile"
                        className="img-fluid rounded driver-image"
                      />
                    </div> */}
                    <div className="col-md-4">
                      <p>
                        <strong>CouponCode:</strong>
                        {"  "}
                        {couponData.CouponCode}
                      </p>
                      <p>
                        <strong>Description:</strong>
                        {"  "} {couponData.Description}
                      </p>
                      <p>
                        <strong>OfferValue:</strong>
                        {"  "} {couponData.OfferValue}
                      </p>
                      <p>
                        <strong>MaxDiscount:</strong>
                        {"  "}
                        {couponData.MaxDiscount}
                      </p>
                      <p>
                        <strong>StartDate:</strong>
                        {"  "}
                        {couponData.StartDate}
                      </p>
                      <p>
                        <strong>EndDate:</strong>
                        {"  "}
                        {couponData.EndDate}
                      </p>
                      <p>
                        <strong>Type:</strong>
                        {"  "}
                        {couponData.Type}
                      </p>
                      <p>
                        <strong>CreatedAt:</strong>
                        {"  "}
                        {couponData.CreatedAt}
                      </p>
                      <p>
                        <strong>UpdatedAt:</strong>
                        {"  "}
                        {couponData.UpdatedAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </Layout>
    </>
  );
};

export default Coupon;
